import { useGlobalAuth } from '@keyliving/component-lib';
import { isTokenExpired } from '@keyliving/utils';

import { clearAuthDetails, selectAuthData } from '../redux/modules/auth';
import { useAppDispatch, useAppSelector } from './redux';

export default function useAuth() {
    const { claims, token, user } = useAppSelector(selectAuthData);
    const dispatch = useAppDispatch();
    const { deleteToken: deleteAuthToken } = useGlobalAuth();
    const tokenExpired = isTokenExpired(claims?.exp ?? null);

    const signOut = () => {
        dispatch(clearAuthDetails());
        deleteAuthToken();
        sessionStorage.clear();
        return Promise.resolve();
    };

    return {
        user,
        claims,
        token,
        isLoggedIn: !!user && !tokenExpired,
        signOut,
    };
}
