import { captureException } from '@sentry/react';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { SUPPORT_EMAIL } from '../../constants';
import FullScreenFallbackLayout from '../layout/FullScreenFallbackLayout';
import Whoops from '../Whoops';
import classes from './RouteError.module.scss';

export default function RouteError() {
    const error = useRouteError();

    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Uncaught error:', error);
    }

    useEffect(() => {
        captureException(error);
    }, [error]);

    return (
        <FullScreenFallbackLayout>
            <Whoops message="Something went wrong">
                <div className="content">
                    <p>
                        Looks like something went wrong that we can&lsquo;t recover from.
                        <br />
                        If the issue continues, please{' '}
                        <a className={classes.link} href={`mailto:${SUPPORT_EMAIL}`}>
                            contact support
                        </a>
                        .
                    </p>

                    {error instanceof Error && process.env.NODE_ENV === 'development' ? (
                        <div className={classes['error-msg']}>
                            <pre>{error.stack}</pre>
                        </div>
                    ) : null}

                    <p>
                        <a className={classes.link} href="/">
                            Reset Application
                        </a>
                    </p>
                </div>
            </Whoops>
        </FullScreenFallbackLayout>
    );
}
