import { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';

import FullScreenFallbackLayout from '../../components/layout/FullScreenFallbackLayout';
import PrivateRoute from '../../components/PrivateRoute';
import RouteError from '../../components/RouteError';
import { homeSearchAuthRoutes, portalAuthRoutes, publicRoutes } from '../routes';

export default function getRoutes(): RouteObject[] {
    /**
     * TODO: Add logic here to determine if we should return home search
     * or resident portal routes.
     */
    // eslint-disable-next-line no-constant-condition
    if (false) {
        return [
            ...publicRoutes,
            {
                /**
                 * Our authRoutes are wrapped in the PrivateRoute component so if a user
                 * navigates to them and is not logged in, they are redirected to the
                 * login page vs getting a 404 if we didn't create them.
                 */
                path: '/',
                element: <PrivateRoute />,
                children: portalAuthRoutes,
            },
        ];
    }

    return [
        ...publicRoutes,
        {
            /**
             * Our authRoutes are wrapped in the PrivateRoute component so if a user
             * navigates to them and is not logged in, they are redirected to the
             * login page vs getting a 404 if we didn't create them.
             */
            path: '/',
            element: (
                <Suspense fallback={<FullScreenFallbackLayout isLoading />}>
                    <PrivateRoute />
                </Suspense>
            ),
            errorElement: <RouteError />,
            children: homeSearchAuthRoutes,
        },
    ];
}
