import { IconProps } from './types';

export default function CalendarIcon({ height, width, ...rest }: IconProps) {
    return (
        <svg
            height={height}
            viewBox="0 0 19.5 17.89"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path d="M15.75 1.26H15V.75a.75.75 0 0 0-1.5 0v.51H6V.75a.75.75 0 0 0-1.5 0v.51h-.75A3.75 3.75 0 0 0 0 5.01v9.13a3.76 3.76 0 0 0 3.75 3.75h12a3.76 3.76 0 0 0 3.75-3.75V5.01a3.75 3.75 0 0 0-3.75-3.75Zm-12 1.5h.78v.51a.75.75 0 1 0 1.5 0v-.51h7.5v.51a.75.75 0 1 0 1.5 0v-.51h.75a2.25 2.25 0 0 1 2.25 2.25v.45H1.53v-.45a2.25 2.25 0 0 1 2.22-2.25Zm12 13.63h-12a2.25 2.25 0 0 1-2.25-2.25V6.96H18v7.18a2.25 2.25 0 0 1-2.25 2.25Z" />
            <path d="M5.25 8.82h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM10.25 8.82h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM15.25 8.82h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM5.25 12.18h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM10.25 12.18h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5ZM15.25 12.18h-1a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5Z" />
        </svg>
    );
}
