// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/a11y';

import classNames from 'classnames/bind';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import dashboard from '../../static/dashboard.png';
import homeSearch from '../../static/home-search.png';
import nextSteps from '../../static/next-steps.png';
import welcomeScene from '../../static/welcome-scene.png';
import NextButton from './NextButton';
import classes from './Welcome.module.scss';

const cx = classNames.bind(classes);

const STORAGE_KEY = 'showWelcome';

/**
 * TODO: Save when welcome closed to a user state DB table to prevent user from seeing
 * welcome message again if they change devices or clear their browser.
 */

export default function Welcome() {
    const [showWelcome, setShowWelcome] = useState(() => {
        const savedShowWelcome = localStorage.getItem(STORAGE_KEY);

        if (savedShowWelcome === null) {
            return true;
        }

        return savedShowWelcome === 'true';
    });
    const [isEnd, setIsEnd] = useState<boolean>(false);

    const handleOnEnd = useCallback(() => {
        localStorage.setItem(STORAGE_KEY, String(false));
        setShowWelcome(false);
    }, []);

    if (!showWelcome) {
        return null;
    }

    return createPortal(
        <div className={cx('wrapper')}>
            <div className={classes.container}>
                <Swiper
                    modules={[Pagination, A11y]}
                    onSlideChange={(swiper) => {
                        setIsEnd(swiper.isEnd === true);
                    }}
                    pagination={{
                        clickable: true,
                        bulletClass: classes.bullet,
                        bulletActiveClass: classes['bullet--active'],
                    }}
                    slidesPerView={1}
                    spaceBetween={0}
                >
                    <SwiperSlide className={classes.slide}>
                        <h1 className={classes.slide__title}>
                            Welcome to Key. <br />
                            We&apos;re happy you&apos;re here
                        </h1>
                        <div className={cx('slide__content')}>
                            <p>
                                We want to introduce you to a few important features of your new
                                Resident Hub that help make your home journey easy and enjoyable.
                            </p>
                        </div>
                        <div className={cx('slide-img', 'slide-img--welcome')}>
                            <div className={classes['slide-img__graphic']}>
                                <img
                                    alt="Dashboard"
                                    className={classes['slide-img__graphic__img']}
                                    src={welcomeScene}
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={classes.slide}>
                        <h1 className={classes.slide__title}>Your new dashboard</h1>
                        <div className={cx('slide__content')}>
                            <p>
                                We&apos;ve designed your Resident Hub with a dashboard. This will be
                                home base. From here you can start a home search, review your home
                                budget and brush up on our education materials.
                            </p>
                        </div>
                        <div className={classes['slide-img']}>
                            <div className={classes['slide-img__graphic']}>
                                <img
                                    alt="Dashboard"
                                    className={classes['slide-img__graphic__img']}
                                    src={dashboard}
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={classes.slide}>
                        <h1 className={classes.slide__title}>Start your home search</h1>
                        <div className={cx('slide__content')}>
                            <p>
                                Finding the right place is very personal. Customize your home search
                                to find the place that best suites you.
                            </p>
                        </div>
                        <div className={classes['slide-img']}>
                            <div className={classes['slide-img__graphic']}>
                                <img
                                    alt="Dashboard"
                                    className={classes['slide-img__graphic__img']}
                                    src={homeSearch}
                                />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={classes.slide}>
                        <h1 className={classes.slide__title}>Complete your profile</h1>
                        <div className={cx('slide__content')}>
                            <p>
                                Things happen quickly and we want you to be ready to submit a offer
                                when the perfect place comes along.
                            </p>
                        </div>
                        <div className={classes['slide-img']}>
                            <div className={classes['slide-img__graphic']}>
                                <img
                                    alt="Dashboard"
                                    className={classes['slide-img__graphic__img']}
                                    src={nextSteps}
                                />
                            </div>
                        </div>
                    </SwiperSlide>

                    <div className={classes['container-end']}>
                        <NextButton
                            onNext={(swiper) => {
                                if (!isEnd) {
                                    swiper.slideNext();
                                    return;
                                }

                                handleOnEnd();
                            }}
                        >
                            {isEnd ? 'Continue to dashboard' : 'Next'}
                        </NextButton>
                    </div>
                </Swiper>
            </div>
        </div>,
        document.body
    );
}
