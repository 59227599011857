import '@keyliving/component-lib/dist/css/global.css';
import './scss/app.scss';

import { withProfiler, wrapCreateBrowserRouterV6 } from '@sentry/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HydrationGate from './components/HydrationGate';
import Welcome from './components/Welcome';
import getRoutes from './routes/lib';

/**
 * Feature flags are to opt in to new features and prepare for the next major version update (v7).
 * For details on each of the feature flags see: https://reactrouter.com/6.29.0/upgrading/future
 */

const sentryCreateBrowserRouter = wrapCreateBrowserRouterV6(createBrowserRouter);
const router = sentryCreateBrowserRouter(getRoutes(), {
    future: {
        v7_relativeSplatPath: true,
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_skipActionErrorRevalidation: true,
    },
});

function App() {
    return (
        <HydrationGate>
            <>
                <RouterProvider
                    future={{
                        v7_startTransition: true,
                    }}
                    router={router}
                />
                <Welcome />
            </>
        </HydrationGate>
    );
}

export default withProfiler(App);
